<template>
  <span :class="params && params.context && params.context.componentParent.tilemode === 'list' ? '' : 'container-lrg'">
    <template v-if="icon != null">
      <font-awesome-icon v-if="!previewUrl" :icon="icon" :class="params && params.context && params.context.componentParent.tilemode === 'list' ? 'file-label-icon' : 'file-label-icon-lrg'" fixed-width/>
      <img v-else :class="params && params.context && params.context.componentParent.tilemode === 'list' ? 'thumbnail-sml' : 'thumbnail-lrg'" :src="previewUrl" @error="previewUrl = null">
    </template>
    <span class="file-label-text">
    {{ label }}
    </span>
  </span>
</template>

<script>
import Vue from 'vue';
import * as mime from 'mime-types';

export default Vue.extend({
  name: 'FileLabelCellRenderer',
  data() {
    return {
      icon: null,
      label: null,
      previewUrl: null
    }
  },
  created() {
    const type = typeof this.params.data.type !== 'undefined' ? this.params.data.type : 'unknown';
    const value = this.params.value;
    this.icon = this.fileIcon(type);
    this.previewUrl = this.params.data && this.isSupportedImage(this.params.data.type) ? `${process.env.BASE_URL}api/file/${this.params.data.uuId}?${Date().valueOf()}` : null;
    let fileExtension = `.${mime.extension(type)}`;
    if ((value && value.endsWith(fileExtension)) || fileExtension === '.false') {
      fileExtension = '';
    }
    this.label = typeof value !== 'undefined' ? `${value}${this.icon? fileExtension: ''}` : 'N/A';
  },
  methods: {
    isSupportedImage(type) {
      if (type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/bmp' ||
          type === 'image/svg+xml' ||
          type === 'image/gif' ||
          type === 'image/tiff') {
        return true;
      }
      return false;
    },
    fileIcon(type) {
      return type.startsWith('audio')
        ? ['far', 'file-audio']
        : (type.includes('excel') || type.includes('spreadsheet'))
        ? ['far', 'file-excel']
        : type.startsWith('text')
        ? ['far', 'file-word']
        : type.endsWith('pdf')
        ? ['far', 'file-pdf']
        : type.startsWith('image')
        ? ['far', 'file-image']
        : type.startsWith('video')
        ? ['far', 'file-video']
        : (type.includes('archive') || type.includes('compressed') || type.includes('zip') || type.includes('rar') || type.includes('tar') ||  type.includes('x-freearc'))
        ? ['far', 'file-archive']
        : type === 'folder'
        ? ['far','folder']
        : ['far', 'file'];
    }
  }
});
</script>

<style lang="scss">
.file-label-icon {
  font-size: 18px;
  top: 4px;
  opacity: 0.7;
}

.file-label-icon-lrg {
  font-size: 40px;
  position: relative;
  top: 25%;
  opacity: 0.7;
  width: 80px !important;
}

.file-label-text {
  padding-left: 6px;
}

.container-lrg {
  display: flex;
  line-height: 80px;
  height: 80px;
}

.thumbnail-sml {
  object-fit: contain;
  width: 25px;    
  height: 23px;
  padding-bottom: 4px;
}

.thumbnail-lrg {
  object-fit: contain;
  width: 80px;
  height: 77px;
}
</style>