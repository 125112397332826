<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="xl" @shown="initModal" :title="labelTitle" footer-class="footerClass"
      @hidden="hidden"
      content-class="shadow"
      no-close-on-backdrop
    >
      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <b-input-group class="mb-1 no-focus">
        <b-input-group-prepend class="border">
          <font-awesome-icon :icon="['far','folder']" class="folder-path-icon"/>
        </b-input-group-prepend>
        <b-form-input type="text" id="pathname" v-model="currentPathName" readonly class="rounded-0 form-input-readonly border"/>
      </b-input-group>
    
      <div class="action-container grid-toolbar">
        <b-button-toolbar justify class="py-1 action-toolbar w-50">
          <b-button-group class="pl-1 action">
            <template v-if="allowManage">
              <template v-if="canAdd()">
                <span :id="`FOLDER_BTN_ADD_${id}`">
                  <b-btn @click="folderOpen(true)"><font-awesome-icon :icon="['far', 'folder-plus']"/></b-btn>
                </span>
                <b-popover :target="`FOLDER_BTN_ADD_${id}`" triggers="hover" placement="top">
                  {{ $t('folder.title_new') }}
                </b-popover>
              </template>
              <template v-if="canView()">
                <span :id="`FOLDER_BTN_EDIT_${id}`">
                <b-btn :disabled="disableFolderEdit" @click="folderOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
                </span>
                <b-popover :target="`FOLDER_BTN_EDIT_${id}`" triggers="hover" placement="top">
                  {{ $t('folder.title_detail') }}
                </b-popover>
              </template>
              <template v-if="canDelete()">
                <span :id="`FOLDER_BTN_DELETE_${id}`">
                  <b-btn :disabled="disableDeleteFolder" @click="deleteFolder"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
                </span>
                <b-popover :target="`FOLDER_BTN_DELETE_${id}`" triggers="hover" placement="top">
                  {{ $t('folder.delete_folder') }}
                </b-popover>
              </template>
            </template>
          </b-button-group>
        </b-button-toolbar>

        <b-button-toolbar justify class="py-1 action-toolbar w-50">
          <b-button-group class="pl-1 action">
            <template v-if="allowManage && canAdd()">
              <span :id="`FILE_BTN_UPLOAD_${id}`">
                <b-btn :disabled="disableUpload" @click="promptUpload"><font-awesome-icon :icon="['far', 'upload']"/></b-btn>
              </span>
              <b-popover :target="`FILE_BTN_UPLOAD_${id}`" triggers="hover" placement="top">
                {{ $t('file.upload_file') }}
              </b-popover>
            </template>
            <template v-if="canView()">
              <span :id="`FILE_BTN_EDIT_${id}`">
                <b-btn :disabled="disableDetails" @click="fileDetails"><font-awesome-icon :icon="'SELECT' === mode?['far','rectangle-list']:['far','pen-to-square']"/></b-btn>
              </span>
              <b-popover :target="`FILE_BTN_EDIT_${id}`" triggers="hover" placement="top">
                {{ $t('file.title.details') }}
              </b-popover>
            </template>
            <template v-if="allowManage && canEdit()">
              <span :id="`FILE_BTN_MOVE_${id}`">
                <b-btn :disabled="disableDownload" @click="fileMove"><font-awesome-icon :icon="['far', 'up-right-from-square']"/></b-btn>
              </span>
              <b-popover :target="`FILE_BTN_MOVE_${id}`" triggers="hover" placement="top">
                {{ $t('file.move_file') }}
              </b-popover>
            </template>
            <template v-if="canView()">
              <span :id="`FILE_BTN_DOWNLOAD_${id}`">
                <b-btn :disabled="disableDownload" @click="fileDownload"><font-awesome-icon :icon="['far', 'download']"/></b-btn>
              </span>
              <b-popover :target="`FILE_BTN_DOWNLOAD_${id}`" triggers="hover" placement="top">
                {{ $t('file.download_file') }}
              </b-popover>
            </template>
            <template v-if="allowManage && canDelete()">
              <span :id="`FILE_BTN_DELETE_${id}`">
                <b-btn :disabled="disableDelete" @click="fileDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              </span>
              <b-popover :target="`FILE_BTN_DELETE_${id}`" triggers="hover" placement="top">
                {{ $t('file.delete_file') }}
              </b-popover>
            </template>
            <template v-if="allowManage">
              <b-btn :id="`FILE_BTN_LIST_${id}`" @click="toggleTileMode" class="preview-button"><font-awesome-icon :icon="['far', tilemode === 'list' ? 'th-list' : 'th-large']"/></b-btn>
              <b-popover :target="`FILE_BTN_LIST_${id}`" triggers="hover" placement="top">
                {{ tilemode === 'list' ? $t('file.list') : $t('file.thumbnail') }}
              </b-popover>
            </template>
            <template v-if="allowManage">
              <b-btn :id="`FILE_BTN_PREVIEW_${id}`" :pressed.sync="showPreview" class="preview-button"><font-awesome-icon :icon="['far','image']"/></b-btn>
              <b-popover :target="`FILE_BTN_PREVIEW_${id}`" triggers="hover" placement="top">
                {{ $t('file.showPreview') }}
              </b-popover>
            </template>        
            <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
              <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="color-dropdown action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far', 'palette']"/>
                </template>
                <b-dropdown-group :header="$t('colorby')">
                  <b-dropdown-item @click="onColorChange('none')" href="#">
                    <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('file')" href="#">
                    <span class="action-item-label">{{ $t('file.coloring.file') }}</span><font-awesome-icon class="active-check" v-if="coloring.file" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>
            </span>
          </b-button-group>
        </b-button-toolbar>
      </div>

      <div class="pane-container">
        <div class="folder-pane">
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham file-selector-grid-height" id="folderGrid"
                :gridOptions="folderGridOptions"
                @grid-ready="onFolderGridReady"
                :autoGroupColumnDef="autoFolderGroupColumnDef"
                :columnDefs="[]"
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :defaultColDef="defaultColDef"
                :getRowId="params => { return params.data.uuId; }"
                :rowData="rowData"
                rowModelType="serverSide"
                :serverSideInfiniteScroll="true"
                suppressContextMenu
                suppressMultiSort
                suppressRowClickSelection
                treeData
                >
        </ag-grid-vue>
        </div>
        <div class="files-pane">
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham file-selector-grid-height" id="fileGrid"
                :gridOptions="fileGridOptions"
                @grid-ready="onFileGridReady"
                :columnDefs="fileColumnDefs"
                :defaultColDef="fileDefaultColDef"
                :context="context"
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :getRowId="params => { return params.data.uuId; }"
                :rowData="rowData"
                rowMultiSelectWithClick
                rowModelType="serverSide"
                :rowSelection="multiple?'multiple':'single'"
                suppressContextMenu
                suppressMultiSort
                >
          </ag-grid-vue>
          <div v-if="previewUrl !== null" class="file-preview">
            <img :src="previewUrl" @error="previewImageError"/>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal title="Confirm Deletion"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ strFormat($i18n.t('file.confirmation.delete_file'), selected.length > 1? 's':'') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal title="Confirm Deletion"
        v-model="confirmDeleteFolderShow"
        @ok="confirmDeleteFolderOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $i18n.t(hasChildNodes()?'file.confirmation.delete_folder_and_child_files':'file.confirmation.delete_folder', [selectedFolder.length === 1 ? selectedFolder[0].name : '']) }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <FolderModal :currentPath="currentPath" :id="folderId" :name="selectedFolderName" :parentData="selectedParent" :show.sync="folderShow" @success="folderSuccess" :title="folderTitle" :readOnly="!canEdit()"/>
   
    <FileDetailsModal :id="detailsId" :currentPath="currentPath" :parentData="selectedParent" :show.sync="detailsShow" @success="fileDetailsSuccess" :mode="mode"/>
   
    <FolderSelectorModal :currentPath="currentPath" :show.sync="promptFolder" @success="folderSelectedSuccess"/>


    <b-modal title="Download"
      centered
      content-class="shadow"
      no-close-on-backdrop
      v-model="downloadProgressShow" hide-footer>
      <b-progress :max="100" height="2rem">
        <b-progress-bar :value="downloadPercentage" :label="`${downloadPercentage.toFixed(0)}%`"/>
      </b-progress>
      <div class="d-flex justify-content-center">
        <b-button class="visible mt-2" variant="secondary"  @click="downloadCancel">
          {{ $i18n.t('button.cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { cloneDeep } from 'lodash';
import alertStateEnum from '@/enums/alert-state';
import { strFormat, strRandom, forceFileDownload, invertColor } from '@/helpers';
import { fileService, viewProfileService } from '@/services';
import { folderService } from '@/services';
import FileLabel from '@/components/Aggrid/CellRenderer/FileLabel.vue';
import FolderLabel from '@/components/Aggrid/CellRenderer/FolderLabel.vue';

const customComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  if (!valueA && !valueB) {
    return 0;
  }
  
  if (nodeA.data.type === 'folder' &&
      nodeB.data.type !== 'folder') {
    return isInverted ? 1 : -1;  
  }
  else if (nodeA.data.type !== 'folder' &&
      nodeB.data.type === 'folder') {
    return isInverted ? -1 : 1;  
  }
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

function ServerSideFolderDatasource(self, func, api) {
  const gridApi = api;
  return {
    getRows(params) {
      
      const groupKeys = params.request.groupKeys;
      let currentId = groupKeys.length !== 0 ? groupKeys[groupKeys.length - 1] : null;

      // fake root node
      if (currentId === null) {
        let rows = [{uuId: '-1', name: '/', folders: []}];

        params.successCallback(rows, rows.length);
        const rowNode = gridApi.getRowNode('-1');
        if (rowNode !== null) {
          rowNode.setExpanded(true);
          if (self.currentPath !== null && typeof self.currentPath['-1'] !== 'undefined' &&
              Object.keys(self.currentPath).length === 1) {
            delete self.currentPath['-1'];
            rowNode.setSelected(true);
            self.selectedFolder = [{uuId: rowNode.data.uuId, name: rowNode.data.name}];
          }
        }
        self.currentPathName = self.getSelectedFolderPathName();
        return;
      }
      
      // handling for getting root node values
      if (currentId === '-1') {
        currentId = null;
      }
      
      func('data/folderList', currentId).then((response) => {  
        const data = response;
        let rows = typeof data[data.jobCase].folders !== 'undefined' ? data[data.jobCase].folders : [];
        const order = "asc";
        rows.sort(function(a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return order === "asc" ? -1 : 1;
          }
          else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        });
        
        params.successCallback(rows, rows.length);
        
        // Update node to indicate if it has no children
        const hasNoSubFolderIds = rows.filter(i => !i.hasSubFolder).map(i => i.uuId);
        hasNoSubFolderIds.forEach(i => {
          const rowNode = gridApi.getRowNode(i);
          if(rowNode !== null) {
            rowNode.group = false;
          }
        });
        
        // expand the added child nodes if they were previously selected
        for (let idx = 0, len = rows.length; idx < len; idx++) {
          const rowId = rows[idx].uuId;
          const rowNode = gridApi.getRowNode(rowId);
          if (rowNode !== null) {
            if (self.currentPath !== null && typeof self.currentPath[rowId] !== 'undefined') {
              delete self.currentPath[rowId];
              rowNode.setExpanded(true);
              if (Object.keys(self.currentPath).length === 0) {
                rowNode.setSelected(true);
                gridApi.ensureIndexVisible(rowNode.rowIndex);
                self.selectedFolder = [{uuId: rowNode.data.uuId, name: rowNode.data.name}];
                self.reloadFileData();
              }
            }
          }
        }
        
        if (gridApi.getSelectedNodes().length !== 0) {
          self.selectedParent = gridApi.getSelectedNodes()[0].parent.data;
        }
        else {
          self.selectedParent = null;
        }
        self.currentPathName = self.getSelectedFolderPathName();
      })
      .catch(() => {
        params.successCallback([], 0);
        gridApi.showNoRowsOverlay();
      });
    }
  }
}

function ServerSideFileDatasource(self, func, api) {
  const gridApi = api;
  return {
    getRows(params) {
      // don't get data when initially setting the data source
      if (!self.fileSourceSet) {
        self.fileSourceSet = true;
        params.successCallback([], 0);
        return;
      }
      let currentId = self.selectedFolder.length !== 0 ? self.selectedFolder[0].uuId : null;
      
      // handling for getting root node values
      if (currentId === '-1') {
        currentId = null;
      }
    
      func('data/folderList', currentId).then((response) => {  
        const data = response;
        let rows = typeof data[data.jobCase].files !== 'undefined' ? data[data.jobCase].files : [];

        //Filter out file with banned type.
        const blackListed = self.bannedMimeType? self.bannedMimeType: [];
        const whiteListed = self.allowedMimeType? self.allowedMimeType: [];
        if(blackListed.length > 0) {
          rows = rows.filter(i => !blackListed.some(j => i.type.includes(j)));
        }
        if(whiteListed.length > 0) {
          rows = rows.filter(i => whiteListed.some(j => i.type.includes(j)));
        }
        
        if (params.request.sortModel.length !== 0) {
          const order = params.request.sortModel[0].sort;
          rows.sort(function(a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return order === "asc" ? -1 : 1;
            }
            else if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return order === "asc" ? 1 : -1;
            }
            return 0;
          });
        }
        
        // combine with the folders to look more like a real file explorer
        self.$store.dispatch('data/folderList', currentId).then((response) => {  
          const data = response;
          let folders = typeof data[data.jobCase].folders !== 'undefined' ? data[data.jobCase].folders : [];
          const order = "asc";
          folders.sort(function(a, b) {
            if (!a.name && !b.name) {
              return 0;
            }
            
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return order === "asc" ? -1 : 1;
            }
            else if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return order === "asc" ? 1 : -1;
            }
            return 0;
          });        
          for (const folder of folders) {
            folder.type = 'folder';
          }
          const allContent = folders.concat(rows);
          params.successCallback(allContent, allContent.length);
          
          // select the file
          if (self.uploadedId) {
            const rowNode = gridApi.getRowNode(self.uploadedId);
            if (rowNode) {
              rowNode.setSelected(true);
            }
            self.uploadedId = null;
          }
        });
        
      })
      .catch(() => {
        params.successCallback([], 0);
        gridApi.showNoRowsOverlay();
      });
    }
  }
}

export default {
  name: 'FileSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    FolderModal: () => import('./FolderModal.vue'),
    FolderSelectorModal: () => import('./FolderSelectorModal.vue'),
    FileDetailsModal: () => import('./FileDetailsModal.vue'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'folderLabel': FolderLabel,
    'fileLabel': FileLabel
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    title: { 
      type: String,
      default: null
    },
    titleEdit: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      default: 'BOTH'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    allowedMimeType: {
      type: Array,
      default: () => []  // ['image/','image/jpeg']
    },
    bannedMimeType: {    // Note: bannedMimeType takes precedent over allowedMimeType.
      type: Array,
      default: () => []  // ['image/','image/jpeg']
    }
  },
  data: function() {
    return {
      userId: null,
      id: `FILE_LIST_${strRandom(5)}`,
      permissionName: 'STORAGE_FILE',
      folderGridOptions: null,
      fileGridOptions: null,
      folderGridApi: null,
      fileGridApi: null,
      folderGridColumnApi: null,
      fileGridColumnApi: null,
      fileColumnDefs: null,
      defaultColDef: null,
      fileDefaultColDef: null,
      rowData: null,
      autoFolderGroupColumnDef: null,
      autoFileGroupColumnDef: null,
      context: null,
  
      modalShow: false,
      selected: [],
      selectedFolder: [],
      selectedParent: null,
      
      folderId: null,
      selectedFolderName: null,
      folderShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,      

      downloadProgressShow: false,
      downloadPercentage: 0,
      downloadCancelTokenSource: null,
      downloadCancelled: false,

      confirmDeleteShow: false,
      confirmDeleteFolderShow: false,

      // moving a file
      promptFolder: false,
      currentPath: null,
      currentPathName: null,

      // file details
      detailsShow: false,
      detailsId: null,
      
      // for restoring the previously open folder
      previousPath: { '-1': true },
      
      fileSourceSet: false,
      uploadedId: null,
      
      previewUrl: null,
      timeoutId: null,
      
      settings: {},
      showPreview: true,
      folders: {},
      tilemode: 'list',
      
      coloring: {
        none: true,
        file: false
      }
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId; 
    const self = this;   
    this.folderGridOptions = {
      rowSelection: 'single',
      
      // indicate if row is a group node
      isServerSideGroup: function (/*dataItem*/) {
          return true;
      },
  
      // specify which group key to use
      getServerSideGroupKey: function (dataItem) {
          return dataItem.uuId;
      }
    };
    this.fileGridOptions = {
      getRowStyle: params => {
        if (params.data &&
            params.data.color &&
            self.coloring.file) {
            return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
        }
      },
      // indicate if row is a group node
      isServerSideGroup: function (/*dataItem*/) {
          return false;
      },
      // specify which group key to use
      getServerSideGroupKey: function (dataItem) {
          return dataItem.uuId;
      }
    };
    this.fileColumnDefs = [
      {
        headerName: this.$t('file.field.file'),
        field: 'name',
        checkboxSelection: (params) => { 
          return params.data.type !== 'folder'
        },
        cellRenderer: 'fileLabel',
        hide: false,
        sort: 'asc',
        minWidth: 200,
        comparator: customComparator
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      suppressMenu: true
    };

    this.fileDefaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      suppressMenu: true
    };
    this.fileDefaultColDef.suppressMenu = false;
    this.fileDefaultColDef.menuTabs = ['columnsMenuTab'];

    this.autoFolderGroupColumnDef = {
      headerName: this.$t('folder.field.folder'),
      field: 'name',
      sortable: false,
      cellRendererParams: { 
        checkbox: false,
        suppressCount: true,
        innerRenderer: 'folderLabel'
      },
      minWidth: 500,
    };
    this.autoFileGroupColumnDef = {
      headerName: this.$t('file.field.file'),
      checkboxSelection: true,
      field: 'name',
      cellRendererParams: { 
        suppressCount: false,
        innerRenderer: 'fileLabel'
      }
    };
    this.context = {
      componentParent: this
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
    const fileProfileKey = 'file_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.folderGridOptions.onRowClicked = function(event) {
      self.folderSelectionChanged(event, false);
    }

    this.fileGridOptions.onRowDoubleClicked = function(event) {
      if (event.data.type === 'folder') {
        self.folderSelectionChanged(event, false);
      }
    }

    this.fileGridOptions.onSelectionChanged = function(event) {
      self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data)));
      
      if (event.api.getSelectedNodes().length !== 0) {
        self.selectedParent = event.api.getSelectedNodes()[0].parent.data;
      }
      else {
        self.selectedParent = null;
      }
    }

    this.fileGridOptions.onCellMouseOver = function(event) {
      if (self.showPreview) {
        self.timeoutId = setTimeout(() => {
        self.previewUrl = event.data && event.data.type && event.data.type.startsWith('image') ? `${process.env.BASE_URL}api/file/${event.data.uuId}` : null;
        }, 1000);
      }
    };
    
    this.fileGridOptions.onCellMouseOut = function(/**event*/) {
      clearTimeout(self.timeoutId);
      self.previewUrl = null;
      self.timeoutId = null;
    };
    
    this.fileGridOptions.getRowHeight = (/**params*/) => self.tilemode === 'thumbnail' ? 80 : 25;
    
    this.folderGridOptions.onColumnVisible = function(event) {
      event.api.autoSizeColumns();// on expanding a tree item resize the column to fit the expanded data
    }

    this.fileGridOptions.onColumnVisible = (event) => {
      const columns = event.columnApi.getAllDisplayedColumns();
      self.settings[fileProfileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    }
    this.fileGridOptions.onSortChanged = (event) => {
      const columns = event.columnApi.getAllDisplayedColumns();
      self.settings[fileProfileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    },
    this.fileGridOptions.onDragStopped = (event) => {
      const columns = event.columnApi.getAllDisplayedColumns();
      self.settings[fileProfileKey] = columns.map(c => getColumnDefs(c));
      self.updateViewProfile();
    }
    this.fileGridOptions.onFirstDataRendered = (event) => {
      if (self.file_newToProfile != null && self.file_newToProfile == true) {
        self.file_newToProfile = null;
        event.api.sizeColumnsToFit();
        self.$nextTick(() => {
          const columns = event.columnApi.getAllDisplayedColumns();
          self.settings[fileProfileKey] = columns.map(c => getColumnDefs(c));
          self.updateViewProfile();
        })
      }
    }
  },
  created() {
    this.updateModalShow(this.show);
    this.strFormat = strFormat;
  },
  beforeDestroy() {
    this.strFormat = strFormat;
    window.removeEventListener('orientationchange', this.detectOrientationChange);
    this.file_newToProfile = null;
    
  },
  watch: {
    show(newValue) {
      if(!newValue) {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.folderGridApi = null;
        this.folderGridColumnApi = null;
        this.fileGridApi = null;
        this.fileGridColumnApi = null;
        this.uploadedId = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    folderTitle() {
      return this.folderId && this.folderId.indexOf('FOLDER_NEW') == -1? this.$t('folder.title_detail'): this.$t('folder.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$i18n.t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    disableOk() {
      return this.selected.length < 1 || this.selected[0].type === 'folder';
    },
    disableFolderEdit() {
      return this.selectedFolder.length != 1 || this.selectedFolder[0].uuId === '-1'
    },
    disableDelete() {
      return this.selected.length < 1 || this.selected[0].type === 'folder';
    },
    disableDetails() {
      return this.selected.length != 1 || this.selected[0].type === 'folder';
    },
    disableDeleteFolder() {
      return this.selectedFolder.length != 1 || this.selectedFolder[0].uuId === '-1'
    },
    disableDownload() {
      return this.selected.length < 1 || this.selected[0].type === 'folder';
    },
    disableUpload() {
      return this.selectedFolder.length != 1
    },
    labelTitle() {
      return this.title? this.title : this.$i18n.t('file.title.list');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    previewImageError() {
      this.previewUrl = null;
    },
    async updateViewProfile() {
      await viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.file_newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
          if (typeof self.settings.lastFolder !== 'undefined') {
            self.previousPath = self.settings.lastFolder;
          }
          if (typeof self.settings.showPreview !== 'undefined') {
            self.showPreview = self.settings.showPreview;
          }
          if (typeof self.settings.tilemode !== 'undefined') {
            self.tilemode = self.settings.tilemode;
          }
          if (typeof self.settings.file_selector_list != 'undefined') {
            self.loadFileColumnSettings(self, self.settings.file_selector_list);
            self.coloring.none = self.settings.file_selector_coloring ? self.settings.file_selector_coloring.none : false;
            self.coloring.file = self.settings.file_selector_coloring ? self.settings.file_selector_coloring.file : false;
          } else {
            self.file_newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadFileColumnSettings(data, columns) {
      if (data == null || data.fileGridOptions == null || data.fileGridOptions.api == null) {
        return;
      }
      const columnDefs = data.fileColumnDefs;
      const gridApi = data.fileGridOptions.api;

      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          columnDefs.splice(idx++, 0, columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }

      gridApi.setColumnDefs([]);
      gridApi.setColumnDefs(columnDefs);
    },
    getSelectedFolderPath() {
      let path = {};
      let rowNode = this.selectedFolder.length !== 0 ? this.folderGridApi.getRowNode(this.selectedFolder[0].uuId) : null;
      
      while (rowNode) {
        path[rowNode.data.uuId] = true;
        rowNode = rowNode.parent;
        if (typeof rowNode.data === 'undefined') {
          break;
        }
      }
      return path;
    },  
    getSelectedFolderPathName() {
      let path = '';
      let rowNode = this.selectedFolder.length !== 0 && this.folderGridApi !== null ? this.folderGridApi.getRowNode(this.selectedFolder[0].uuId) : null;
      
      while (rowNode) {
        if (rowNode.data) {
          path = `${rowNode.data.name} / ${path}`;
          rowNode = rowNode.parent;
          if (typeof rowNode.data === 'undefined') {
            break;
          }
        }
      }
      return path.startsWith('/ /')? path.substring(1) : path === '' ? '/' : path;
    },
    folderSelectionChanged(event, init) {
      const self = this;
      if (!init) {
        self.selectedFolder = typeof event.data !== 'undefined' ? [{uuId: event.data.uuId, name: event.data.name}] : [];
        if (typeof event.node !== 'undefined' && event.node.parent !== null) {
          self.selectedParent = event.node.parent.data;
        }
        else {
          self.selectedParent = null;
        }
      }
      let rowNode = self.selectedFolder.length !== 0 && self.folderGridApi !== null ? self.folderGridApi.getRowNode(self.selectedFolder[0].uuId) : null;
      if(rowNode) {
        rowNode.setSelected(true);
        rowNode.setExpanded(true);
      }

      self.currentPathName = self.getSelectedFolderPathName();

      if (!init) {
        this.previousPath = this.getSelectedFolderPath();
      }
          
      self.reloadFileData();
      if (self.fileGridApi != null) {
        self.fileGridApi.deselectAll();
      }
    },
    async onFolderGridReady(params) {
      // restore previous path
      await this.loadViewProfile();
      if (this.previousPath !== null) {
        this.currentPath = cloneDeep(this.previousPath);
      }
    
      this.folderGridApi = this.folderGridOptions.api;
      this.folderGridColumnApi = this.folderGridOptions.columnApi;
      const self = this;
      const updateData = () => {
        let datasource = new ServerSideFolderDatasource(self, this.$store.dispatch, self.folderGridApi);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
      this.currentPathName = '/';
    },
    onFileGridReady(params) {
      this.fileGridApi = this.fileGridOptions.api;
      this.fileGridColumnApi = this.fileGridOptions.columnApi;

      const self = this;
      const updateData = () => {
        let datasource = new ServerSideFileDatasource(self, this.$store.dispatch, self.fileGridApi);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.folderGridApi) {
          this.folderGridApi.sizeColumnsToFit();
        }
      }, 100);
    },
    initModal() {
      if(this.folderGridApi != null) {
        this.folderGridApi.sizeColumnsToFit();
      }
      this.folderSelectionChanged({ data: { uuId: '-1', name: '/'} }, true);
    },
    folderOpen(isNew) {
      this.currentPath = this.getSelectedFolderPath();
      if(isNew) {
        this.folderId = `FOLDER_NEW_${strRandom(5)}`;
        
        // for a new file the selected node will be the parent of the new file
        if (this.selectedFolder.length !== 0) {
          this.selectedParent = this.selectedFolder[0];
        }
        else {
          this.selectedParent = null;
        }
      } else {
        this.folderId = this.selectedFolder[0].uuId;
        this.selectedFolderName = this.selectedFolder[0].name;
        this.selectedParent = this.folderGridApi.getRowNode(this.selectedFolder[0].uuId).parent.data;
      }
      this.folderShow = true;
      this.resetAlert();
    },
    reloadFileData() {
      if (this.fileGridApi !== null) {
        this.fileGridApi.refreshServerSide({ purge: true });
      } 
    },
    reloadFolderData(init = false) {
      if (!init) {
        this.currentPath = this.getSelectedFolderPath();
      }
      this.folderGridApi.refreshServerSide({ purge: true });
    },
    folderSuccess(payload) {
      const originalParentId = this.selectedParent? this.selectedParent.uuId: '-1';
      if(originalParentId === payload.parentId) {
        this.reloadFolderData();
        this.reloadFileData();
      } else {
        this.selected = [];
        this.selectedParent = null;
        this.folderSelectionChanged({ data: { uuId: '-1', name: '/'} }, false);
        this.reloadFolderData();
      }
      this.resetAlert({ msg: payload.msg });
    },
    fileDetailsSuccess(payload) {
      this.uploadedId = payload.fileId;
      this.reloadFileData();
      if(payload.msg) {
        this.resetAlert({ msg: payload.msg });
      }
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    fileDelete() {
      this.confirmDeleteShow = true;
    },
    fileMove() {
      // prompt for the new parent
      this.currentPath = this.getSelectedFolderPath();
      this.promptFolder = true;
    },
    addFileToFolder(folderId, fileId) {
      const self = this;
      folderService.link(folderId, fileId, 'file').then((/*response*/) => {  
        self.reloadFileData();
        self.resetAlert({ msg: self.$i18n.t('file.move') });
        if (self.fileGridApi != null) {
          self.fileGridApi.deselectAll();
        }
      })
      .catch(() => {
        self.resetAlert({ msg: self.$i18n.t('file.error.failed_to_move') });
      });
    },
    folderSelectedSuccess(payload) {
      const self = this;
      // move all the selected files to the new folder
      for (let idx = 0; idx < this.selected.length; idx++) {
        const uuId = this.selected[idx].uuId;
        if (this.selectedFolder.length !== 0 && this.selectedFolder[0].uuId !== '-1') {
          folderService.unlink(this.selectedFolder[0].uuId, uuId, 'file').then((/*response*/) => { 
            if (payload.uuId !== '-1') {
              self.addFileToFolder(payload.uuId, uuId);
            }
            else {
              self.reloadFileData();
            }
          })
          .catch(() => {
            self.resetAlert({ msg: self.$i18n.t('file.error.failed_to_move') });
          });
        }
        else {
          // no folder selected, file is in root with no parent
          this.addFileToFolder(payload.uuId, uuId);
          this.fileGridApi.deselectAll();
        }
      }
    },
    fileDetails() {
      this.detailsId = this.selected[0].uuId;
      this.selectedParent = this.selectedFolder.length !== 0 ? this.selectedFolder[0] : {uuId: '-1', name: '/'};   
      this.currentPath = this.getSelectedFolderPath();
      this.detailsShow = true;
    },
    deleteFolder() {
      this.confirmDeleteFolderShow = true;
    },
    fileDownload() {
      const toDownloadIds = this.selected.map(i => { return { uuId: i.uuId, name: encodeURIComponent(i.name), type: i.type } });
      forceFileDownload(toDownloadIds, this);
    },
    promptUpload() {
      this.detailsId = 'FILE_NEW';
      this.selectedParent = this.selectedFolder.length !== 0 ? this.selectedFolder[0] : {uuId: '-1', name: '/'};
      this.detailsShow = true;
    },
    hasChildNodes() {
      if(this.folderGridApi) {
        return this.folderGridApi.getSelectedNodes() != null && this.folderGridApi.getSelectedNodes().length > 0? !!this.folderGridApi.getSelectedNodes()[0].group: false;
      } else {
        return false;
      }
    },
    async confirmDeleteOk(){ 
      const toDeleteIds = this.selected.map(i => { return i.uuId  });
      const toDeleteIdNames = this.selected.map(i => { return { uuId: i.uuId, name: i.name } });
      
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`file.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      const requests = [];
      for (let i = 0, len = toDeleteIds.length; i < len; i++) {
        requests.push(fileService.remove(toDeleteIds[i]));
      }
      if (requests.length > 0) {
        Promise.all(requests.map(p => p.catch(e => e)))
        .then((responses) => {
          const failedIdNames = [];
          for (let j = 0, jLen = responses.length; j < jLen; j++) {
            if (!(responses[j] instanceof Error)) {
              continue;
            }
            failedIdNames.push(toDeleteIdNames[j].name);
          }
          if (failedIdNames.length > 0) {
            alertState = alertStateEnum.WARNING;
            alertMsg = this.$t(`file.delete_partial`);
            if (failedIdNames.length == toDeleteIds.length) {
              alertState = alertStateEnum.ERROR;
              alertMsg = this.$t(`file.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
            } 
            alertMsgDetailTitle = this.$t(`file.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            alertMsgDetailList.splice(0, alertMsgDetailList.length, ...failedIdNames);
          }
          this.selected = [];
          this.selectedParent = null;
          if (this.fileGridApi != null) {
            this.fileGridApi.deselectAll();
          }
          this.reloadFileData();

          const alertPayload = {
            msg: alertMsg,
            alertState: alertState
          }
          if (alertMsgDetailList.length > 0) {
            alertPayload.details = alertMsgDetailList;
            alertPayload.detailTitle = alertMsgDetailTitle;
          }
          this.resetAlert(alertPayload);
        })
        .catch(e => {
          this.httpAjaxError(e);
        });
      }
    },
    confirmDeleteFolderOk(){ 
      const toDeleteIds = this.selectedFolder.map(i => { return i.uuId  });
      const toDeleteIdNames = this.selectedFolder.map(i => { return { uuId: i.uuId, name: i.name } });
      
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`folder.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      const requests = [];
      for (let i = 0, len = toDeleteIds.length; i < len; i++) {
        requests.push(folderService.remove(toDeleteIds[i]));
      }
      if (requests.length > 0) {
        Promise.all(requests.map(p => p.catch(e => e)))
        .then((responses) => {
          const failedIdNames = [];
          for (let j = 0, jLen = responses.length; j < jLen; j++) {
            if (!(responses[j] instanceof Error)) {
              continue;
            }
            failedIdNames.push(toDeleteIdNames[j].name);
          }
          if (failedIdNames.length > 0) {
            alertState = alertStateEnum.WARNING;
            alertMsg = this.$t(`folder.delete_partial`);
            if (failedIdNames.length == toDeleteIds.length) {
              alertState = alertStateEnum.ERROR;
              alertMsg = this.$t(`folder.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
            } 
            alertMsgDetailTitle = this.$t(`folder.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            alertMsgDetailList.splice(0, alertMsgDetailList.length, ...failedIdNames);
          }
          this.selected = [];
          this.selectedParent = null;
          this.folderSelectionChanged({ data: { uuId: '-1', name: '/'} }, false);
          this.reloadFolderData();

          const alertPayload = {
            msg: alertMsg,
            alertState: alertState
          }
          if (alertMsgDetailList.length > 0) {
            alertPayload.details = alertMsgDetailList;
            alertPayload.detailTitle = alertMsgDetailTitle;
          }
          this.resetAlert(alertPayload);
        })
        .catch(e => {
          this.httpAjaxError(e);
        });
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    ok() {
      this.$emit('ok', cloneDeep(this.selected));
      this.$emit('update:show', false);
    },
    async hidden() {
      this.settings.lastFolder = this.previousPath;
      this.settings.showPreview = this.showPreview;
      await this.updateViewProfile();
      this.resetAlert();
      this.$emit('update:show', false)
    },
    toggleTileMode() {
      this.tilemode === 'list' ? this.tilemode = 'thumbnail' : this.tilemode = 'list';
      this.settings['tilemode'] = this.tilemode;
      this.updateViewProfile();
      this.reloadFileData();
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['file_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.fileGridApi.redrawRows();
    },
  }
}


</script>

<style lang="scss">
  .ag-overlay-no-rows-center {
    color: var(--text-medium);
  }
  
  .file-selector-grid-height {
    height: calc(100vh - 350px);
    min-height: 250px;
  }

  .pane-container, .action-container {
    display: flex;
    flex-direction: row;
  }

  .folder-pane, .files-pane {
    width: 50%;
  }

  .folder-path-icon {
    margin: auto 10px;
  }

  .action-container {
    border-top: 1px solid var(--border-medium) !important;
    border-left: 1px solid var(--border-medium) !important;
    border-right: 1px solid var(--border-medium) !important;
  }
  
  .action-container .action-toolbar {
    .action {
      .btn,.btn[disabled]:active {
        background-color: transparent;
        border: 0;
      }
    }
    .btn:focus,.btn:active:focus {
      box-shadow: none;
    }
    .action {
      &.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
      &.btn-group > .btn-group:not(:last-child) > .btn,
      &.btn-group > .btn:not(:first-child):not(.dropdown-toggle),
      &.btn-group > .btn-group:not(:first-child) > .btn {
        border-radius: 15%;
      }
      &.btn-lg {
        &.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
        &.btn-group > .btn-group:not(:last-child) > .btn,
        &.btn-group > .btn:not(:first-child):not(.dropdown-toggle),
        &.btn-group > .btn-group:not(:first-child) > .btn {
          border-radius: 15%;
        }
      }
    }
  }

  .no-focus {
    box-shadow: none !important;
  }

  .form-input-readonly {
    background-color: transparent !important;
  }
  
  .file-preview {
    background-color: var(--surface-bg);
    position: absolute;
    top: 0;
    right: 100px;
    width: 200px;
    height: 200px;
    pointer-events: none;
  }
  
  .file-preview img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    box-shadow: 0 10px 16px 0 var(--bs-shadow);
  }
  
  .preview-button {
    z-index: 0 !important;
  }
  
  .preview-button:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  
  .active-check {
    position: absolute;
    right: 10px;
  }
  
  .color-dropdown .btn:hover {
     background-color: var(--grid-toolbar-dropdown-bg) !important;
   }
   
  .color-dropdown.show .dropdown-toggle {
     color: var(--grid-toolbar-button) !important;
     background-color: var(--grid-toolbar-dropdown-bg) !important;
   }
</style>